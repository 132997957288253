// i18n.js

import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js";

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

function getDirection(locale) {
  const rtlLanguages = ['ar']; // Add any other RTL languages here
  return rtlLanguages.includes(locale) ? 'rtl' : 'ltr';
}

// Load the previously selected language and direction from browser storage
const userSelectedLanguage = localStorage.getItem('selectedLanguage');
const userSelectedDirection = localStorage.getItem('selectedDirection');

// If the user has previously selected a language, use that. Otherwise, use 'ar'.
const defaultLocale = userSelectedLanguage || 'ar';
const defaultDirection = userSelectedDirection || getDirection(defaultLocale);

// Save the direction to localStorage
localStorage.setItem('selectedDirection', defaultDirection);

const i18n = createI18n({
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  locale: defaultLocale,
  fallbackLocale: 'ar',
  messages: loadLocaleMessages()
});

export default i18n;
